<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12">
            <div class="d-flex flex-wrap align-items-center justify-content-between">
              <div class="my-1 my-md-0">Jurusan : {{namaJurusan}}</div>

              <div>
                <b-button class="mr-2 my-1 my-md-0" variant="primary" @click="fetchData">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="text-nowrap">Refresh</span>
                </b-button>

                <b-button class="my-1 my-md-0" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.tambah-daya-tampung variant="primary">
                  <feather-icon icon="PlusCircleIcon" class="mr-50" />
                  <span class="text-nowrap">Tambah Daya Tampung</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- modal tambah kategori Tryout-->
      <b-modal id="tambah-daya-tampung" cancel-variant="outline-secondary" ok-title="Tambah" cancel-title="Cancel" centered size="lg" title="Tambah Daya Tampung" @show="resetModal" @hidden="resetModal" @ok="submitJurusan">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="nama">Tahun</label>
              <b-form-input for="nama" v-model.trim="form.tahun" type="text" placeholder="Tahun" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="nama">Daya Tampung</label>
              <b-form-input for="nama" v-model.trim="form.daya_tampung" type="text" placeholder="Daya Tampung" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <!-- modal edit kampus-->
      <b-modal size="lg" id="edit-daya-tampung" cancel-variant="outline-secondary" ok-title="Edit" cancel-title="Cancel" centered title="Edit Daya Tampung" @ok="editDayaTampung(listDayaTampung[currentDayaTampungIndex].id)">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="nama">Tahun</label>
              <b-form-input for="nama" v-model.trim="form.tahun" type="text" placeholder="Tahun" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="nama">Daya Tampung</label>
              <b-form-input for="nama" v-model.trim="form.daya_tampung" type="text" placeholder="Daya Tampung" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <!-- modal hapus kampus-->
      <b-modal id="hapus-kampus" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Hapus" cancel-title="Cancel" centered title="Hapus" @ok="hapusJurusan(listDayaTampung[currentDayaTampungIndex].id)">
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-form-group>
              <p>Apakah anda yakin ingin menghapus?</p>
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- MODAL LIST KAMPUS -->
      <b-table :busy="isLoading" class="position-relative" :items="listDayaTampung" responsive :fields="tableColumns" primary-key="id" show-empty striped hover empty-text="Tidak ada data ditemukan">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="setRowIndex(data.item)" v-b-modal.edit-daya-tampung>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="setRowIndex(data.item)" v-b-modal.hapus-kampus>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <!-- MODAL LIST KAMPUS -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BFormRadio,
  BFormSelect,
  BCardText,
  BButton,
  BTable,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
// import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import { useRouter } from "@core/utils/utils";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import repository from "@repofactory";
const repoDayaTampung = repository.get("DayaTampungRepository");

export default {
  components: {
    // ModalTambah,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormCheckbox,
    VBModal,
    BForm,
    BFormGroup,
    BCardText,
    BDropdown,
    flatPickr,
    BDropdownItem,
    ToastificationContent,
    BCardActions,
    draggable,
    BListGroupItem,
    vSelect,
    BOverlay,
    DatePicker,
    BFormRadio,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast();
    const { route } = useRouter();
    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const isError = ref(false);
    const currentDayaTampungIndex = ref(null);

    let id_kampus = route.value.params.id_kampus;
    let id_jurusan = route.value.params.id_jurusan;

    const form = ref({
      tahun: "",
      daya_tampung: "",
    });

    const listDayaTampung = ref([]);
    let namaJurusan = ref();

    const tableColumns = [
      {
        key: "id",
        label: "ID",
        sortable: true,
      },
      {
        key: "tahun",
        label: "Tahun",
        sortable: true,
      },
      {
        key: "daya_tampung",
        label: "Daya Tampung",
        sortable: true,
      },
      {
        key: "actions",
      },
    ];

    onMounted(async () => {
      fetchData();
    });

    const setForm = () => {
      form.value.tahun =
        listDayaTampung.value[currentDayaTampungIndex.value].tahun;
      form.value.daya_tampung =
        listDayaTampung.value[currentDayaTampungIndex.value].daya_tampung;
    };

    const setRowIndex = (item) => {
      currentDayaTampungIndex.value = listDayaTampung.value.findIndex(
        (daya_tampung) => daya_tampung.id == item.id
      );

      setForm();
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const resetModal = () => {
      form.value = {
        nama: "",
        kategori: "",
        nilai: "",
        nilai_min_asli: "",
      };
    };

    const fetchData = async () => {
      isLoading.value = true;

      await repoDayaTampung
        .get(id_kampus, id_jurusan)
        .then(function (response) {
          isLoading.value = false;
          listDayaTampung.value =
            response.data.data.daya_tampung_jurusan_kampuses;
          namaJurusan.value = response.data.data.nama;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isLoading.value = false;
          isError.value = true;
        });
    };

    const submitJurusan = async () => {
      isSubmitting.value = true;

      const data = {
        jurusan_kampus_id: id_jurusan,
        tahun: form.value.tahun,
        daya_tampung: form.value.daya_tampung,
      };

      await repoDayaTampung
        .create(data, id_kampus, id_jurusan)
        .then((response) => {
          listDayaTampung.value.push(response.data.data);

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const editDayaTampung = async (id_daya_tampung) => {
      isSubmitting.value = true;

      const data = {
        jurusan_kampus_id: id_jurusan,
        tahun: form.value.tahun,
        daya_tampung: form.value.daya_tampung,
      };

      await repoDayaTampung
        .update(data, id_kampus, id_jurusan, id_daya_tampung)
        .then((response) => {
          listDayaTampung.value[currentDayaTampungIndex.value].tahun =
            response.data.data.tahun;
          listDayaTampung.value[currentDayaTampungIndex.value].daya_tampung =
            response.data.data.daya_tampung;

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const hapusJurusan = async (id_daya_tampung) => {
      isSubmitting.value = true;

      await repoDayaTampung
        .delete(id_kampus, id_jurusan, id_daya_tampung)
        .then((response) => {
          listDayaTampung.value.splice(currentDayaTampungIndex.value, 1);
          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const options = [
      { value: "SAINTEK", text: "SAINTEK" },
      { value: "SOSHUM", text: "SOSHUM" },
    ];

    return {
      isError,
      isLoading,
      isSubmitting,
      tableColumns,
      form,
      listDayaTampung,
      currentDayaTampungIndex,
      options,

      // methods
      setRowIndex,
      fetchData,
      resetModal,
      submitJurusan,
      editDayaTampung,
      hapusJurusan,

      namaJurusan,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
